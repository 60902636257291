import React, { useContext, useEffect, useState } from "react";
import "./product.scss";
import ProductImage from "../../assets/images/product-image.jpg";
import BestServiceProvider from "../about/bestServiceProvider";
import { ApiGet } from "../../Helpers/Api/ApiData";
import { ToastContainer, toast } from "react-toastify";
import { NavLink, useHistory } from "react-router-dom";
import DummyPDF from "../../assets/Pdf/SamplePDF.pdf";

export default function Product() {
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const history = useHistory();

  useEffect(() => {
    GetProduct();
  }, []);
  const newredirect = (e) => {
    history.push("/productDetails");
  };

  const GetProduct = async () => {
    // setIsLoaderVisible(true);
    console.log("inn");
    await ApiGet(`product/list`)
      .then((res) => {
        console.log("getblog", res);
        // setIsLoaderVisible(false);
        setProductData(res?.data?.data?.result);
      })
      .catch((err) => {
        console.log("err", err);
        toast.error(err);
      });
  };
  console.log("productData", productData);
  return (
    <>
      <div className="new-page-banner-service">
        <div className="container">
          <div className="new-box-center-alignment">
            <div className="new-box">
              <h1>Our Products</h1>
              <p>
                <span>Home</span> - Products
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="product-details-section-content-alignment">
          <div className="container">
            <div className="grid">
              {productData?.map((data, index) => {
                console.log("datadatadata", data);
                return (
                  <div
                    className="animate__animated grid-items animate__fadeInUp wow"
                    data-wow-offset="150"
                    key={index}
                  >
                    <div
                      className="card-image"
                      onClick={() => {
                       ( data?.product_pdf && data?.product_pdf !=="" )&&
                        window.open(
                          data?.product_pdf
                            ? `https://api.magnitochemicals.com${data?.product_pdf}`
                            : `https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf`
                        )
                      }}
                    >
                      <img
                        // src={`https://api.magnitochemicals.com${data?.image}`}
                        src={`https://api.magnitochemicals.com${data?.image[0]}`}
                        alt="ProductImage"
                      />
                    </div>
                    <div className="card-content-alignment">
                      <div>
                        <NavLink to={`/productDetails/${data?._id}`}>
                          <h1>{data?.title}</h1>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <div
              className="animate__animated grid-items animate__fadeInUp wow"
              data-wow-offset="150"
            >
              <NavLink to="/productDetails">
                <div className="card-image">
                  <img src={ProductImage} alt="ProductImage" />
                  <div className="image-blur"></div>
                </div>
                <div className="card-content-alignment">
                  <div>
                    <h1>Micro Fertilizer</h1>
                  </div>
                </div>
              </NavLink>
            </div>
            <div
              className="animate__animated grid-items animate__fadeInUp wow"
              data-wow-offset="150"
            >
              <div className="card-image">
                <img src={ProductImage} alt="ProductImage" />
                <div className="image-blur"></div>
              </div>
              <div className="card-content-alignment">
                <div>
                  <h1>Micro Fertilizer</h1>
                </div>
              </div>
            </div> */}
            </div>
          </div>
        </div>
        <>
          <BestServiceProvider />
        </>
      </div>
    </>
  );
}
