import React, { useEffect, useState } from "react";
import { ApiGet } from "../../../Helpers/Api/ApiData";
import "./bestServiceProvider.scss";
export default function BestServiceProvider() {
  const [servicesData, setServicesData] = useState();
  useEffect(() => {
    GetBestServices();
  }, []);

  const GetBestServices = async () => {
    await ApiGet(`bestServices`)
      .then((res) => {
        console.log("bestServices", res?.data?.data?.result);
        if (res?.status === 200) {
          setServicesData(res?.data?.data?.result);
        }
      })
      .catch((err) => {
        console.log("err", err?.message);
      });
  };
  return (
    <>
      <div className="best-service-provider-section-alignment">
        <div className="container">
          <div className="page-title">
            <h1
              className="animate__animated animate__fadeInUp wow"
              data-wow-offset="150"
            >
              {servicesData?.title}
            </h1>
            <div className="divider-title-center">
              <span></span>
            </div>
            <p
              className="animate__animated animate__fadeInUp wow"
              data-wow-offset="150"
            >
              {servicesData?.description}
            </p>
          </div>
          <div
            className="text-grid animate__animated animate__fadeInUp wow"
            data-wow-offset="150"
          >
            <div className="text-grid-items">
              <h4>{servicesData?.history?.title}</h4>
              <p>{servicesData?.history?.description}</p>
            </div>
            <div className="text-grid-items">
              <h4>{servicesData?.reliability?.title}</h4>
              <p>{servicesData?.reliability?.description}</p>
            </div>
            <div className="text-grid-items">
              <h4>{servicesData?.tools?.title}</h4>
              <p>{servicesData?.tools?.description}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
