import React, { useContext, useRef, useState } from "react";
import "./header.scss";
import Logo from "../../assets/logo/logo.png";
import Loader from "../../assets/logo/loader.png";
import { ApiPost } from "../../Helpers/Api/ApiData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Loader.css";
import { NavLink } from "react-router-dom";

import MenuIcon from "../../assets/icons/menu-icon.png";
import { DataLoaded } from "../../App";

export default function Header() {
  const { Logo } = useContext(DataLoaded);
  const [hlogo, setHlogo] = Logo;
  const [headerOpen, setHeaderOpen] = useState(false);

  return (
    <>
      <header>
        <ToastContainer />
        <div className="container">
          <div className="header-alignment">
            <div className="logo">
              <NavLink to="/">
                {/* <img src={Logo} alt="Logo" /> */}
                <img
                  src={`https://api.magnitochemicals.com${hlogo?.logo}`}
                  alt=""
                />
              </NavLink>
            </div>
            <div className="menu">
              <NavLink to="/">Home</NavLink>
              <NavLink to="/about">About</NavLink>
              <NavLink to="/service-page">Services</NavLink>
              <NavLink to="/gallery">Gallery</NavLink>
              <NavLink to="/product">Product</NavLink>
              <NavLink to="/Contact">Contact</NavLink>
            </div>

            <div
              className="mobile-menu"
              onClick={() => setHeaderOpen(!headerOpen)}
            >
              <img src={MenuIcon} alt="MenuIcon" />
            </div>
          </div>
        </div>
      </header>
      {headerOpen && <div className="header-wrapper"></div>}
      <div
        className={
          headerOpen
            ? "mobile-view-sidebar-design mobile-view-sidebar-design-show"
            : "mobile-view-sidebar-design mobile-view-sidebar-design-hidden"
        }
      >
        <div className="mobile-view-header-alignment">
          <div>
            <div className="logo">
              <NavLink to="/">
                {/* <img src={Logo} alt="Logo" /> */}
                <img
                  src={`https://api.magnitochemicals.com${hlogo?.logo}`}
                  alt=""
                />
              </NavLink>
            </div>
          </div>
          <div onClick={() => setHeaderOpen(false)}>
            <i class="fa-solid fa-xmark"></i>
          </div>
        </div>
        <div className="mobile-view-body-content-alignment">
          <div className="menu">
            <NavLink to="/" onClick={() => setHeaderOpen(false)}>
              Home
            </NavLink>
            <NavLink to="/about" onClick={() => setHeaderOpen(false)}>
              About
            </NavLink>
            <NavLink to="/service-page" onClick={() => setHeaderOpen(false)}>
              Services
            </NavLink>
            <NavLink onClick={() => setHeaderOpen(false)} to="/gallery">
              Gallery
            </NavLink>
            <NavLink onClick={() => setHeaderOpen(false)} to="/product">
              Product
            </NavLink>
            <NavLink onClick={() => setHeaderOpen(false)} to="/Contact">
              Contact
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
