import React, { useEffect, useState } from "react";
import "./marketSection.scss";
import MarketSectionImage from "../../../assets/images/market-section.webp";
import { ApiGet } from "../../../Helpers/Api/ApiData";
import { NavLink } from "react-router-dom";
export default function MarketSection() {
  // useEffect(() => {
  //   window.scrollTo({ top: 1000, left: 50, behavior: "smooth" });
  // }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [sectionData, setSectiondata] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [showMore, setShowMore] = useState(false);

  console.log("sectionData", sectionData);
  console.log("servicesData", servicesData);

  useEffect(() => {
    GetSection();
    GetAllServices();
  }, []);

  const GetSection = async () => {
    // setIsLoaderVisible(true);
    await ApiGet(`whyChooseSection`)
      .then((res) => {
        console.log("first", res?.data?.data?.result);
        if (res?.status === 200) {
          setSectiondata(res?.data?.data?.result);
          // setIsLoaderVisible(false);
        }
      })
      .catch((err) => {
        console.log("err", err?.response);
      });
  };

  const GetAllServices = async () => {
    // setIsLoaderVisible(true);
    await ApiGet(`service/list`)
      .then((res) => {
        console.log(`service`, res);
        // setIsLoaderVisible(false);
        setServicesData(res?.data?.data?.result);
      })
      .catch((err) => {
        console.log("err", err?.response);
      });
  };

  return (
    <>
      <div className="market-section-banner-alignment">
        <div className="container">
          <div className="grid">
            <div className="grid-items">
              <div className="image-style">
                <img
                  src={`https://api.magnitochemicals.com${sectionData?.image}`}
                  alt="MarketSectionImage"
                />
              </div>
            </div>
            <div className="grid-items">
              <div className="content-style">
                <h6>{sectionData?.main_title}</h6>
                <div className="divider-title">
                  <span></span>
                </div>
                <h3>{sectionData?.sub_title}</h3>
                <p
                  dangerouslySetInnerHTML={
                    // showMore === true
                    //   ? 
                      { __html: sectionData?.description }
                      // : { __html: sectionData?.description?.substring(0, 250) }
                  }
                ></p>
                {/* <div className="fill-button">
                  <button
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                    onClick={() => {
                      setShowMore(!showMore);
                    }}
                  >
                    {showMore ? "Show Less" : "Show More"}
                  </button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="all-service-contnet-alignment">
        <div className="container">
          <div className="page-title">
            <h2>Our Services</h2>
            <div className="divider-title-center">
              <span></span>
            </div>
          </div>
          <div className="grid">
            {servicesData?.slice(0, 3)?.map((data, index) => {
              return (
                <div className="grid-items " key={index}>
                  <div className="sub-grid">
                    <div className="sub-grid-items">
                      <div className="icon-design">
                        <img
                          className="img-designss"
                          src={`https://api.magnitochemicals.com${data?.image}`}
                        />
                      </div>
                    </div>
                    <div className="sub-grid-items">
                      <h1
                        className="animate__animated animate__slideInUp wow"
                        data-wow-offset="150"
                      >
                        {data?.title}
                      </h1>
                      <p
                        className="animate__animated animate__slideInUp wow"
                        data-wow-offset="150"
                      >
                        {data?.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div
            className="fill-button"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "60px 0 0 0",
            }}
          >
            <NavLink to="/service-page">
              <button>View More</button>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
