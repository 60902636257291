import React, { useEffect, useState } from "react";
import "./pageTop.scss";
export default function PageTop() {
  const [showArrow, setShowArrow] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY === 0 || currentScrollY < 5) {
        setShowArrow(false);
      } else {
        setShowArrow(true);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <>
      {showArrow && (
        <div>
          <div
            className="page-top-alignment"
            onClick={() =>
              window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
              })
            }
          >
            <i className="fa-solid fa-chevron-up"></i>
          </div>
        </div>
      )}
    </>
  );
}
