import React, { useEffect, useState } from "react";
import "./exploreProject.scss";
import { ApiGet } from "../../../Helpers/Api/ApiData";
import Slider from "react-slick";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";
import RightArrow from "../../../assets/icons/rightArrow.png";
import leftArrow from "../../../assets/icons/leftArrow.png";
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="leftArrow" onClick={onClick}>
      <img src={RightArrow} alt="RightArrow" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div className="rightArrow" onClick={onClick}>
      <img src={leftArrow} alt="leftArrow" />
    </div>
  );
}

export default function ExploreProject() {
  const [filteredCategory, setFilteredCategory] = useState([]);
  const [headerData, setHeaderdata] = useState([]);

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          infinite: true,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    GetAllExpProject();
    GetProjectHeader();
  }, []);
  console.log("filteredCategory", filteredCategory);
  const GetAllExpProject = async () => {
    // setIsLoaderVisible(true);
    await ApiGet(`exploreProjects/list`)
      .then((res) => {
        console.log("Exploreproject", res);
        // setIsLoaderVisible(false);
        setFilteredCategory(res?.data?.data?.result?.reverse());
      })
      .catch((err) => {
        console.log("err", err?.response);
      });
  };

  const GetProjectHeader = async () => {
    await ApiGet(`exploreHeader`)
      .then((res) => {
        console.log("exploreHeader", res?.data?.data?.result);
        if (res?.status === 200) {
          setHeaderdata(res?.data?.data?.result);
        }
      })
      .catch((err) => {
        console.log("err", err?.message);
      });
  };

  return (
    <>
      <div className="expliore-project-content-alignment">
        <div className="container">
          <div className="page-title">
            <h1
              className="animate__slideInUp animate__animated wow"
              data-wow-offset="150"
            >
              {headerData?.title}
            </h1>
            <div className="divider-title-center">
              <span></span>
            </div>
            <p
              className="animate__slideInUp animate__animated wow"
              data-wow-offset="150"
            >
              {headerData?.description}
            </p>
          </div>
          {/* <Swiper
            slidesPerView={4}
            spaceBetween={30}
            slidesPerGroup={1}
            loop={true}
            loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            // rtl= {true}
            modules={[Pagination, Navigation]}
            breakpoints={{
              310: {
                slidesPerView: 1,
                // spaceBetween:5
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 50,
              },
            }}
            className="mySwiper"
          >
            <div className="grid" style={{ background: "red" }}>
              {" "}
              {filteredCategory?.map((data, index) => {
                return (
                  <SwiperSlide>
                    <div
                      className="grid-items animate__pulse animate__animated wow"
                      data-wow-offset="150"
                      key={index}
                    >
                      <div className="card-image">
                        <img
                          src={`https://api.magnitochemicals.com${data?.image}`}
                          alt="ProductImage1"
                        />
                        <div className="card-blur"></div>
                      </div>
                      <div className="contnet-bottom-alignment">
                        <div>
                          <h3>{data?.title}</h3>
                          <p>{data?.description}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </div>
          </Swiper> */}

          <Slider {...settings}>
            {filteredCategory?.map((data, index) => {
              return (
                <div>
                  <div
                    className="grid-items animate__pulse animate__animated wow"
                    key={index}
                    data-wow-offset="150"
                  >
                    <div className="card-image">
                      <img
                        src={`https://api.magnitochemicals.com${data?.image}`}
                        alt="ProductImage1"
                      />
                      <div className="card-blur"></div>
                    </div>
                    <div className="contnet-bottom-alignment">
                      <div>
                        <h3>{data?.title}</h3>
                        <p>{data?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
          {/* <div className="grid">
            {" "}
            {filteredCategory?.map((data, index) => {
              return (
                <div
                  className="grid-items animate__pulse animate__animated wow"
                  data-wow-offset="150"
                  key={index}
                >
                  <div className="card-image">
                    <img
                      src={`https://api.magnitochemicals.com${data?.image}`}
                      alt="ProductImage1"
                    />
                    <div className="card-blur"></div>
                  </div>
                  
                  <div className="contnet-bottom-alignment">
                    <div>
                      <h3>{data?.title}</h3>
                      <p>{data?.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
      </div>
    </>
  );
}
