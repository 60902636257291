import React, { useEffect, useState } from "react";
import "./about.scss";
import AboutImage from "../../assets/images/about.webp";
import BestServiceProvider from "./bestServiceProvider";
import OurTeam from "./ourTeam";
import { ApiGet } from "../../Helpers/Api/ApiData";
export default function About() {
  const [aboutData, setAboutData] = useState([]);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    GetAbout();
  }, []);

  const GetAbout = async () => {
    // setIsLoaderVisible(true);
    await ApiGet(`aboutUs`)
      .then((res) => {
        console.log("aboutData", res?.data?.data?.aboutUs);
        if (res?.status === 200) {
          setAboutData(res?.data?.data?.aboutUs);
          // setIsLoaderVisible(false);
        }
      })
      .catch((err) => {
        console.log("err", err?.response);
      });
  };
  return (
    <>
      <div className="new-page-banner-service">
        <div className="container">
          <div className="new-box-center-alignment">
            <div className="new-box">
              <h1>Our About</h1>
              <p>
                <span>Home</span> - About
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="about-content-grid-alignment">
        <div className="container">
          <div className="grid">
            <div className="grid-items">
              <img
                className="animate__animated animate__fadeInUp wow"
                data-wow-offset="150"
                src={`https://api.magnitochemicals.com${aboutData?.image}`}
                alt="AboutImage"
              />
            </div>
            <div className="grid-items">
              <h1
                className="animate__animated animate__slideInUp wow"
                data-wow-offset="150"
              >
                {aboutData?.title}
              </h1>
              <div className="divider-title" style={{ textAlign: "left" }}>
                <span></span>
              </div>
              <h2
                className="animate__animated animate__slideInUp wow"
                data-wow-offset="150"
              >
                {aboutData?.sub_title}
              </h2>
              <p
                className="animate__animated animate__slideInUp wow"
                data-wow-offset="150"
              >
                {aboutData?.description}
              </p>
              {/* <div className="fill-button">
                <button
                  className="animate__animated animate__slideInUp wow"
                  data-wow-offset="150"
                >
                  Learn More
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <>
        <BestServiceProvider />
      </>
      <>
        <OurTeam />
      </>
    </>
  );
}
