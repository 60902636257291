import React, { useEffect, useState } from "react";
import "./Gallery.scss";
import NatureImage from "../../assets/images/nature.jpeg";
import { ApiGet } from "../../Helpers/Api/ApiData";
import ImageGallery from "react-image-gallery";

export default function Gallery() {
  const [galleryImage, setGalleryImage] = useState([]);
  const [theArray, setTheArray] = useState([]);
  const [sortedImage, setsortedImage] = useState();
  const [lastArry, setLastArry] = useState();
  const [sliderModalOpen, setSliderModalOpen] = useState(false);
  const [defaultIndex, setdefaultIndex] = useState(0);

  console.log("defaultIndex",defaultIndex);

  useEffect(() => {
    if (!sliderModalOpen) {
      let element = document.getElementById("root");
      element.style.overflow = "auto";
    }
  }, [sliderModalOpen]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    GetAllgallery();
  }, []);

  const GetAllgallery = async () => {
    console.log("mid in GetAllgallery");
    await ApiGet(`gallary/list`)
      .then((res) => {
        console.log("gallery===", res?.data?.data?.result);

        //for react imaghe gallery
        setGalleryImage(
          res?.data?.data?.result?.map((data1) => {
            console.log("data1", data1);
            setTheArray((current) => [
              ...current,
              {
                original: `https://api.magnitochemicals.com${data1?.image}`,
                thumbnail: `https://api.magnitochemicals.com${data1?.image}`,
              },
            ]);
            // setLastArry((current) => [
            //   ...current,
            //   {
            //     original: `https://api.magnitochemicals.com${data1?.image}`,
            //     thumbnail: `https://api.magnitochemicals.com${data1?.image}`,
            //   },
            // ]),
          })
        );
        const finalArray = sortArray(res?.data?.data?.result);
        console.log("finalArray", finalArray);
        setsortedImage(finalArray);
        console.log("remainingImages", remainingImages);
        setLastArry(remainingImages);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  console.log("lastArry", lastArry);
  console.log("theArray", theArray);

  let remainingImages = [];
  const sortArray = (arraytosort) => {
    let sortedImages = [];
    let tempArray = [];
    console.log("array2sort", arraytosort);
    arraytosort.map((data, index) => {
      if ((index + 1) % 7 == 0) {
        tempArray.push(data);
        sortedImages.push(tempArray);
        tempArray = [];
      } else {
        tempArray.push(data);
      }
    });
    remainingImages = tempArray;
    return sortedImages;
  };

  return (
    <>
      <div className="new-page-banner-service">
        <div className="container">
          <div className="new-box-center-alignment">
            <div className="new-box">
              <h1>Our Gallery</h1>

              <p>
                <span>Home</span> - Gallery
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="gallery-section-alignment">
        <div className="container">
          {sortedImage?.map((data, i) => {
            console.log("data===========", i);
            return (
              <>
                <div
                  className="grid"
                  onClick={() => {
                    setSliderModalOpen(!sliderModalOpen);
                    let element = document.getElementById("root");
                    element.style.overflow = "hidden";
                  }}
                >
                  <div className="grid-items" key={i}>
                    <div
                      className="long-image"
                      onClick={() => setdefaultIndex(0)}
                    >
                      <img
                        src={`https://api.magnitochemicals.com${data[0]?.image}`}
                        // onClick={() => {
                        //   setdefaultIndex(0);
                        // }}
                      />
                    </div>
                    <div
                      className="first-image"
                      onClick={() => setdefaultIndex(1)}
                    >
                      <img
                        src={`https://api.magnitochemicals.com${data[1]?.image}`}
                        // onClick={() => {setdefaultIndex(1)
                        // }}
                        alt="NatureImage"
                      />
                    </div>
                  </div>

                  <div className="grid-items">
                    <div
                      className="main-image"
                      onClick={() => setdefaultIndex(2)}
                    >
                      <img
                        src={`https://api.magnitochemicals.com${data[2]?.image}`}
                      />
                    </div>
                  </div>

                  <div className="grid-items">
                    <div
                      className="first-image"
                      onClick={() => setdefaultIndex(5)}
                    >
                      <img
                        src={`https://api.magnitochemicals.com${data[5]?.image}`}
                        alt="NatureImage"
                      />
                    </div>
                    <div
                      className="long-image"
                      onClick={() => setdefaultIndex(4)}
                    >
                      <img
                        src={`https://api.magnitochemicals.com${data[4]?.image}`}
                      />
                    </div>
                  </div>
                </div>

                <div
                  className="sub-grid"
                  onClick={() => {
                    setSliderModalOpen(!sliderModalOpen);
                  }}
                >
                  <div
                    className="sub-grid-items"
                    onClick={() => setdefaultIndex(5)}
                  >
                    <img
                      src={`https://api.magnitochemicals.com${data[5]?.image}`}
                    />
                  </div>
                  <div
                    className="sub-grid-items"
                    onClick={() => setdefaultIndex(6)}
                  >
                    <img
                      src={`https://api.magnitochemicals.com${data[6]?.image}`}
                    />
                  </div>
                </div>
              </>
            );
          })}

          <div
            className="sub-grid"
            onClick={() => setSliderModalOpen(!sliderModalOpen)}
          >
            {lastArry?.map((data, index) => {
              return (
                <>
                  {index % 2 === 0 ? (
                    <>
                      {console.log("index", index)}
                      <div
                        className="sub-grid-items"
                        onClick={() => setdefaultIndex(index)}
                      >
                        <img
                          src={`https://api.magnitochemicals.com${data?.image}`}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      {console.log("elseeq", index)}

                      <div
                        className="sub-grid-items"
                        onClick={() => setdefaultIndex(index)}
                      >
                        <img
                          src={`https://api.magnitochemicals.com${data?.image}`}
                        />
                      </div>
                    </>
                  )}
                </>
              );
            })}
          </div>

          {sliderModalOpen && (
            <div className="slider-modal">
              <div style={{ position: "relative" }}>
                <div
                  className="modal-close-alignment"
                  onClick={() => setSliderModalOpen(false)}
                >
                  <svg
                    viewBox="0 0 14 14"
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current w-1-1/4 h-1-1/4 sm:w-2 sm:h-2"
                  >
                    <path fill="none" d="M1 1l12 12M13 1L1 13"></path>
                  </svg>
                </div>
                <ImageGallery items={theArray} startIndex={defaultIndex}  />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
