import React, { useContext, useEffect, useRef, useState } from "react";
import "./productDetails.scss";
import BestServiceProvider from "../../about/bestServiceProvider";
import { useParams } from "react-router-dom";
import { ApiGet, ApiPost } from "../../../Helpers/Api/ApiData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../../assets/logo/logo.png";
import Loader from "../../../assets/logo/loader.png";
import LeftIcon from "../../../assets/icons/leftArrow.png";
import RightIcon from "../../../assets/icons/rightArrow.png";
import ImageGallery from "react-image-gallery";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import PdfPreview from "./PdfPreview";
import Footer from "../../../components/footer";
import { DataLoaded } from "../../../App";

import PdfHeader from "./PdfHeader";
import PdfFooter from "./PdfFooter";
export default function ProductDetails() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const [productData, setProductData] = useState();
  const [contactModal, setContactModal] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [sliderModalOpen, setSliderModalOpen] = useState(false);
  const [controlledSwiper, setControlledSwiper] = useState("");
  const [theArray, setTheArray] = useState([]);
  const [galleryImage, setGalleryImage] = useState([]);
  const [defaultIndex, setdefaultIndex] = useState(0);
  const [phone, setPhone] = useState("91");
  const [Dloader, setDLoader] = useState(false);
  const [modal, setModal] = useState(false);
  const [showPdf, setShowPdf] = useState();
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(false);
  console.log("controlledSwiper", controlledSwiper);

  const regexEmail =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

  const container = useRef(null);
  const pdfExportComponent = useRef(null);
  const exportPDFWithMethod = () => {
    //different pages
    setDLoader(true);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      setTimeout(() => {
        setDLoader(false);
        setModal(false);
      }, 1000);
    }
    //one page
    // let element = container.current || document.body;
    // savePDF(element, {
    //   paperSize: "auto",
    //   margin: 40,
    //   fileName: "Invoice",
    // }
    // )
    // setDLoader(false)
  };

  const handleClose = () => {
    setModal(false);
    setContactModal(false);
    setErrors("");
  };
  useEffect(() => {
    if (!sliderModalOpen) {
      let element = document.getElementById("root");
      element.style.overflow = "auto";
    }
  }, [sliderModalOpen]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "first_name" && value.charAt(e.length) == " ") return;
    if (name === "surname" && value.charAt(e.length) == " ") return;
    else {
      setInputValue({ ...inputValue, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
  };
  console.log("productData", productData);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="right-slider-icon-alignment" onClick={onClick}>
        <img src={RightIcon} alt="RightIcon" />
      </div>
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className="left-slider-icon-alignment" onClick={onClick}>
        <img src={LeftIcon} alt="LeftIcon" />
      </div>
    );
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  let { id } = useParams();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    GetProduct();
  }, []);
  const GetProduct = async () => {
    // setIsLoaderVisible(true);
    console.log("inn");
    await ApiGet(`product/${id}`)
      .then((res) => {
        console.log("ONE blog", res?.data?.data?.product);
        setProductData(res?.data?.data?.product);
        setGalleryImage(
          res?.data?.data?.product?.image?.map((data1) => {
            setTheArray((current) => [
              ...current,
              {
                original: `https://api.magnitochemicals.com${data1}`,
                thumbnail: `https://api.magnitochemicals.com${data1}`,
              },
            ]);
            console.log("data1", data1);
          })
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  console.log("theArray", theArray);
  const Validation = () => {
    let error = {};
    let Formvalid = true;
    if (!inputValue?.first_name) {
      Formvalid = false;
      error["first_name"] = "First name is required*";
    }
    if (!inputValue?.surname) {
      Formvalid = false;
      error["surname"] = "Last name is required*";
    }
    if (!inputValue.email || regexEmail.test(inputValue.email) === false) {
      Formvalid = false;
      error["email"] = "Email is not valid*";
    }
    if (!phone) {
      Formvalid = false;
      error["phone"] = "Phone number is required*";
    }
    if (!inputValue?.message) {
      Formvalid = false;
      error["message"] = "Message is required*";
    }
    setErrors(error);
    console.log("Formvalid", Formvalid);
    return Formvalid;
  };

  const onSubmit = async (e) => {
    // let data = {
    //   first_name: inputValue?.first_name,
    //   surname: inputValue?.surname,
    //   email: inputValue?.email,
    //   phone: inputValue?.phone,
    //   message: inputValue?.message,
    // };
    if (Validation()) {
      setIsLoading(true);
      inputValue.phone = phone;
      await ApiPost(`contactUs`, inputValue)
        .then((res) => {
          if (res?.status === 200) {
            console.log("res", res);
            setContactModal(false);
            toast.success("Form submitted successfully");
            setInputValue({});
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("erroe", error);
          toast.error("Form is not submitted");
          setIsLoading(false);
        });
    }
  };

  const onButtonClick = (showPdf) => {
    // using Java Script method to get PDF file

    fetch(`https://api.magnitochemicals.com${showPdf}`).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${+new Date()}.${
          showPdf.split(".")[showPdf.split(".").length - 1]
        }`;
        alink.click();
      });
    });
  };

  return (
    <>
      <div className="product-details-section">
        <div className="container">
          <div className="grid">
            <div className="grid-item">
              <div className="images-product-details-all-content">
                <ImageGallery
                  onClick={() => {
                    setSliderModalOpen(!sliderModalOpen);
                    let element = document.getElementById("root");
                    element.style.overflow = "hidden";
                  }}
                  items={theArray}
                  startIndex={defaultIndex}
                  showFullscreenButton={false}
                />
              </div>
            </div>
            <div className="grid-item">
              <div className="product-details-alignemt">
                <div className="product-name">
                  <h4>{productData?.title ? productData?.title : "-"}</h4>
                </div>
                <div className="product-details">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: productData?.description,
                    }}
                  ></p>
                </div>
                {/* <div className="price-tag-alignment">
                  <div className="title">
                    <span>Product ID :</span>
                  </div>
                  <div className="price-alignment">
                    <span>
                      {productData?.product_id ? productData?.product_id : "-"}
                    </span>
                  </div>
                </div>
                <div className="price-tag-alignment">
                  <div className="title">
                    <span>Product Tags :</span>
                  </div>
                  <div className="price-alignment">
                    <a>
                      {" "}
                      {productData?.product_tag
                        ? productData?.product_tag
                        : "-"}
                    </a>
                  </div>
                </div>
                <div></div>
                <div className="atrribute-tag-alignment">
                  <div className="title">
                    <span>Product Attributes :</span>
                  </div>
                  <div className="price-alignment">
                    <div className="sub-details-alignment">
                      <span>MF:</span>{" "}
                      <span>
                        {" "}
                        {productData?.product_attributes?.mf
                          ? productData?.product_attributes?.mf
                          : "-"}
                      </span>
                    </div>
                    <div className="sub-details-alignment">
                      <span>Purity:</span>{" "}
                      <span>
                        {productData?.product_attributes?.purity
                          ? productData?.product_attributes?.purity
                          : "-"}
                      </span>
                    </div>
                    <div className="sub-details-alignment">
                      <span>Size:</span>{" "}
                      <span>
                        {productData?.product_attributes?.size
                          ? productData?.product_attributes?.size
                          : "-"}
                      </span>
                    </div>
                    <div className="sub-details-alignment">
                      <span>CAS NO.:</span>{" "}
                      <span>
                        {productData?.product_attributes?.cas_no
                          ? productData?.product_attributes?.cas_no
                          : "-"}
                      </span>
                    </div>

                    <div className="sub-details-alignment">
                      <span>Appearance:</span>{" "}
                      <span>
                        {productData?.product_attributes?.appearance
                          ? productData?.product_attributes?.appearance
                          : "-"}
                      </span>
                    </div>
                  </div>
                </div> */}
                <div className="fill-button">
                  <button onClick={() => setContactModal(!contactModal)}>
                    Contact Us
                  </button>
                  {productData?.product_pdf &&
                    productData?.product_pdf !== "" && (
                      <button
                        style={{ marginLeft: "10px" }}
                        onClick={(e) => {
                          setModal(true);
                          setShowPdf(productData?.product_pdf);
                        }}
                      >
                        PDF
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <>
        <BestServiceProvider />
      </>
      {sliderModalOpen && (
        <div className="slider-modal">
          <div style={{ position: "relative" }}>
            <div
              className="modal-close-alignment"
              onClick={() => setSliderModalOpen(false)}
            >
              <svg
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current w-1-1/4 h-1-1/4 sm:w-2 sm:h-2"
              >
                <path fill="none" d="M1 1l12 12M13 1L1 13"></path>
              </svg>
            </div>
            <ImageGallery items={theArray} startIndex={defaultIndex} />
          </div>
        </div>
      )}
      {/* contact modal open */}
      {contactModal && (
        <div className="contact-blur">
          <div className="contact-modal">
            <div className="modal-header">
              <div>
                <img src={Logo} />
              </div>
              <div onClick={(e) => handleClose(e)}>
                <i className="fa-solid fa-xmark"></i>
              </div>
            </div>
            <div className="modal-body">
              <div className="modal-title">
                <h1>Contact Details</h1>
              </div>
              <div className="input-grid">
                <div className="input">
                  <label>First Name</label>
                  <input
                    type="text"
                    name="first_name"
                    value={inputValue?.first_name}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <span className="spanError">{errors["first_name"]}</span>
                </div>
                <div className="input">
                  <label>Last Name</label>
                  <input
                    type="text"
                    name="surname"
                    value={inputValue?.surname}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <span className="spanError">{errors["surname"]}</span>
                </div>
              </div>
              <div className="email-new0top-alignment">
                <div className="input">
                  <label>Email</label>
                  <input
                    type="email"
                    name="email"
                    value={inputValue?.email}
                    onChange={(e) => handleOnChange(e)}
                  />
                  <span className="spanError">{errors["email"]}</span>
                </div>
              </div>
              <div className="input">
                <label>Contact Number</label>
                <ReactPhoneInput
                  country={"in"}
                  enableSearch={true}
                  value={phone}
                  onChange={(phone) => setPhone(phone)}
                  className="number-input"
                  // style={{padding : 0}}
                />
                {/* <input
                    type="number"
                    name="phone"
                    value={inputValue?.phone}
                    onChange={(e) =>
                      e?.target?.value?.length <= 10 && handleOnChange(e)
                    }
                  /> */}
                <span className="spanError">{errors["phone"]}</span>
              </div>
              <div className="input text-area-top">
                <label>Message</label>
                <textarea
                  id=""
                  cols="30"
                  rows="10"
                  name="message"
                  value={inputValue?.message}
                  onChange={(e) => handleOnChange(e)}
                ></textarea>
                <span className="spanError">{errors["message"]}</span>
              </div>
              <div className="fill-button">
                <button
                  onClick={(e) => onSubmit(e)}
                  style={{ display: "flex" }}
                >
                  Submit
                  {isLoading == true && (
                    <div class="lds-roller">
                      <img src={Loader} className="Loader-image" alt="loader" />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* contact modal close */}
      {modal && (
        <div className="assign-modal-wrapper">
          <div className="assign-modal-box">
            <div className="modal-header">
              <div className="generate-div">
                <h1>Download Pdf</h1>
                {!error && (
                  <>
                    {loader ? (
                      <div className="pdf-generating">Generating...</div>
                    ) : (
                      <div className="pdf-generated">Pdf Generated</div>
                    )}
                  </>
                )}
              </div>

              <i class="fa-solid fa-xmark" onClick={handleClose}></i>
            </div>
            {/* <div>{Dloader && <span className="loader-72"></span>}</div> */}
            <div className="modal-body">
              <div className="modal-divv">
                {showPdf ? (
                  <iframe
                    className="iframewrapper"
                    src={`https://api.magnitochemicals.com${showPdf}#toolbar=0`}
                  ></iframe>
                ) : (
                  <iframe
                    className="iframewrapper"
                    src={`https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf#toolbar=0`}
                  ></iframe>
                )}

                {/* <PDFExport
                  ref={pdfExportComponent}
                  paperSize="a3"
                  margin={20}
                  fileName="Product"
                  author="KendoReact Team"
                >
                  <div ref={container}>
                    <PdfHeader />
                    <PdfPreview productData={productData} />
                    <PdfFooter />
                  </div>
                </PDFExport> */}
              </div>
            </div>
            {error ? (
              <div className="generate-error">*Could not generate pdf</div>
            ) : !loader ? (
              <div className="pdfViewerButton fill-button pdf-download-button-center-alignment-for-page">
                <button
                  className={
                    showPdf ? "downloadButton" : "downloadButton pdf-disable"
                  }
                  disabled={Dloader || !showPdf}
                  // onClick={exportPDFWithMethod}
                  onClick={(e) => onButtonClick(showPdf)}
                >
                  {" "}
                  Download
                  {/* {Dloader ? "Downloading..." : "Download"} */}
                </button>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
