import React, { useEffect } from "react";
import "./blog.scss";
import BlogImage from "../../assets/images/blog-details.webp";
export default function Blog() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <div className="blog-banner-style">
        <div className="container">
          <div className="page-title">
            <h1>Blog Details</h1>
          </div>
        </div>
      </div>
      <div className="blog-details-content-alignment">
        <div className="container">
          <div className="page-title">
            <h1>Blog</h1>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Possimus
              provident officia quidem optio fugiat, ipsam sequi delectus atque
              sint sapiente temporibus. Omnis nam quasi, itaque ea dicta maxime
              cumque ratione!
            </p>
          </div>
          <div className="grid">
            <div className="grid-items">
              <div className="card-image">
                <img src={BlogImage} alt="BlogImage" />
              </div>
              <div className="card-details">
                <h3>Bringing Food Production Back To Cities</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Provident, minima quisquam tempore blanditiis mollitia impedit
                  ratione magnam distinctio nostrum placeat illum vel rerum nisi
                  laborum maxime neque perferendis. Consequatur, cum!
                </p>
                <span>Read More....</span>
              </div>
            </div>
            <div className="grid-items">
              <div className="card-image">
                <img src={BlogImage} alt="BlogImage" />
              </div>
              <div className="card-details">
                <h3>Bringing Food Production Back To Cities</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Provident, minima quisquam tempore blanditiis mollitia impedit
                  ratione magnam distinctio nostrum placeat illum vel rerum nisi
                  laborum maxime neque perferendis. Consequatur, cum!
                </p>
                <span>Read More....</span>
              </div>
            </div>
            <div className="grid-items">
              <div className="card-image">
                <img src={BlogImage} alt="BlogImage" />
              </div>
              <div className="card-details">
                <h3>Bringing Food Production Back To Cities</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Provident, minima quisquam tempore blanditiis mollitia impedit
                  ratione magnam distinctio nostrum placeat illum vel rerum nisi
                  laborum maxime neque perferendis. Consequatur, cum!
                </p>
                <span>Read More....</span>
              </div>
            </div>
            <div className="grid-items">
              <div className="card-image">
                <img src={BlogImage} alt="BlogImage" />
              </div>
              <div className="card-details">
                <h3>Bringing Food Production Back To Cities</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Provident, minima quisquam tempore blanditiis mollitia impedit
                  ratione magnam distinctio nostrum placeat illum vel rerum nisi
                  laborum maxime neque perferendis. Consequatur, cum!
                </p>
                <span>Read More....</span>
              </div>
            </div>
            <div className="grid-items">
              <div className="card-image">
                <img src={BlogImage} alt="BlogImage" />
              </div>
              <div className="card-details">
                <h3>Bringing Food Production Back To Cities</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Provident, minima quisquam tempore blanditiis mollitia impedit
                  ratione magnam distinctio nostrum placeat illum vel rerum nisi
                  laborum maxime neque perferendis. Consequatur, cum!
                </p>
                <span>Read More....</span>
              </div>
            </div>
            <div className="grid-items">
              <div className="card-image">
                <img src={BlogImage} alt="BlogImage" />
              </div>
              <div className="card-details">
                <h3>Bringing Food Production Back To Cities</h3>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Provident, minima quisquam tempore blanditiis mollitia impedit
                  ratione magnam distinctio nostrum placeat illum vel rerum nisi
                  laborum maxime neque perferendis. Consequatur, cum!
                </p>
                <span>Read More....</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
