import React, { useEffect, useState } from "react";
import "../home/marketSection/marketSection.scss";
import MarketSectionImage from "../../assets/images/market-section.webp";
import { ApiGet } from "../../Helpers/Api/ApiData";
export default function Service() {
  // useEffect(() => {
  //   window.scrollTo({ top: 1000, left: 50, behavior: "smooth" });
  // }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const [sectionData, setSectiondata] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [showMore, setShowMore] = useState(false);

  console.log("sectionData", sectionData);
  console.log("servicesData", servicesData);

  useEffect(() => {
    GetSection();
    GetAllServices();
  }, []);

  const GetSection = async () => {
    // setIsLoaderVisible(true);
    await ApiGet(`whyChooseSection`)
      .then((res) => {
        console.log("first", res?.data?.data?.result);
        if (res?.status === 200) {
          setSectiondata(res?.data?.data?.result);
          // setIsLoaderVisible(false);
        }
      })
      .catch((err) => {
        console.log("err", err?.response);
      });
  };

  const GetAllServices = async () => {
    // setIsLoaderVisible(true);
    await ApiGet(`service/list`)
      .then((res) => {
        console.log(`service`, res);
        // setIsLoaderVisible(false);
        setServicesData(res?.data?.data?.result);
      })
      .catch((err) => {
        console.log("err", err?.response);
      });
  };

  return (
    <>
      {/* <div className="market-section-banner-alignment"> */}
      <div className="new-page-banner-service">
        <div className="container">
          <div className="new-box-center-alignment">
            <div className="new-box">
              <h1>Our Services</h1>
              <p>
                <span>Home</span> - Services
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="all-service-contnet-alignment ">
        <div className="container">
          <div className="grid">
            {servicesData?.map((data, index) => {
              return (
                <div className="grid-items " key={index}>
                  <div className="sub-grid">
                    <div className="sub-grid-items">
                      <div className="icon-design">
                        <img
                          className="img-designss"
                          src={`https://api.magnitochemicals.com${data?.image}`}
                        />
                      </div>
                    </div>
                    <div className="sub-grid-items">
                      <h1
                        className="animate__animated animate__slideInUp wow"
                        data-wow-offset="150"
                      >
                        {data?.title}
                      </h1>
                      <p
                        className="animate__animated animate__slideInUp wow"
                        data-wow-offset="150"
                      >
                        {data?.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}

            {/* <div className="grid-items">
              <div className="sub-grid">
                <div className="sub-grid-items">
                  <div className="icon-design"></div>
                </div>
                <div className="sub-grid-items">
                  <h1
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                  >
                    Excellent Services
                  </h1>
                  <p
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                  >
                    Nam lobortis fringilla felis sce sed utpat mi at urna cras
                    ut nec.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-items">
              <div className="sub-grid">
                <div className="sub-grid-items">
                  <div className="icon-design"></div>
                </div>
                <div className="sub-grid-items">
                  <h1
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                  >
                    Excellent Services
                  </h1>
                  <p
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                  >
                    Nam lobortis fringilla felis sce sed utpat mi at urna cras
                    ut nec.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-items">
              <div className="sub-grid">
                <div className="sub-grid-items">
                  <div className="icon-design"></div>
                </div>
                <div className="sub-grid-items">
                  <h1
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                  >
                    Excellent Services
                  </h1>
                  <p
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                  >
                    Nam lobortis fringilla felis sce sed utpat mi at urna cras
                    ut nec.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-items">
              <div className="sub-grid">
                <div className="sub-grid-items">
                  <div className="icon-design"></div>
                </div>
                <div className="sub-grid-items">
                  <h1
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                  >
                    Excellent Services
                  </h1>
                  <p
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                  >
                    Nam lobortis fringilla felis sce sed utpat mi at urna cras
                    ut nec.
                  </p>
                </div>
              </div>
            </div>
            <div className="grid-items">
              <div className="sub-grid">
                <div className="sub-grid-items">
                  <div className="icon-design"></div>
                </div>
                <div className="sub-grid-items">
                  <h1
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                  >
                    Excellent Services
                  </h1>
                  <p
                    className="animate__animated animate__slideInUp wow"
                    data-wow-offset="150"
                  >
                    Nam lobortis fringilla felis sce sed utpat mi at urna cras
                    ut nec.
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}
