import React, { useState } from "react";
import "./loader.scss";
import LoaderGif from "../../assets/images/loader-gif.gif";
const Loader = () => {
  const [loderTime, setLoderTime] = useState(true);

  setTimeout(() => {
    setLoderTime(false);
  }, 3500);

  return (
    <>
      {loderTime ? (
        <div>
          <div className="custom-loader-design">
            <div>
              <div className="logo-center-alignment">
                <img src={LoaderGif} alt="LoaderGif" />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Loader;
