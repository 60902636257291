import "./App.css";

import "./styles/mixins/global.scss";
import Routes from "./routes";
import { createContext, useState } from "react";
import Loader from "./components/loader";

export const DataLoaded = createContext()
function App() {
  const [hlogo,setHlogo]=useState();
  const store ={
    Logo : [hlogo,setHlogo],
  }
  return (
    <>
    <DataLoaded.Provider value={store} >
      <Loader/>
      <Routes />
    </DataLoaded.Provider>
    </>
  );
}

export default App;
