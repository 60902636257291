import { API } from "../../config/api.config";
import Auth from "../auth";
import * as authUtil from "../../utils/auth.util";
import { Redirect, useHistory } from "react-router-dom";
// import axios from "axios"
export const BaseURL = API.hostUrl;

const axios = require("axios").default;

const defaultHeaders = {
  isAuth: true,
  AdditionalParams: {},
  isJsonRequest: true,
};

export const ApiPostNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        BaseURL + type,
        userData,
        getHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        })
      )
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiPutNoAuth = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        BaseURL + type,
        userData,
        getHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        })
      )
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiGetNoAuth = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        BaseURL + type,
        getHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        })
      )
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const FakeApi = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        type,
        getHttpOptions({
          ...defaultHeaders,
          isAuth: false,
        })
      )
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const Api = (type, methodtype, userData) => {
  return new Promise((resolve, reject) => {
    userData = userData || {};
    axios({
      url: BaseURL + type,
      headers: getHttpOptions(),
      data: userData,
      type: methodtype,
    })
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiGet = (type) => {
  return new Promise((resolve, reject) => {
    axios
      .get(BaseURL + type, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiPost = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          Auth.Logout();
          window.location.reload();
          // return toast.error("Ihr Konto wurde auf einem anderen Gerät angemeldet" )+ <Redirect  to ="/Login" />
        }
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiPut = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .put(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          Auth.Logout();
          window.location.reload();
          // return toast.error("Ihr Konto wurde auf einem anderen Gerät angemeldet" ) + <Redirect  to ="/Login" />
        }
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiPatch = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .patch(BaseURL + type, userData, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

// export const ApiDelete = (type, userData) => {
//   console.log("UserData121212`21211", userData);
//   return new Promise((resolve, reject) => {
//     axios
//       .delete(BaseURL + type, userData, getHttpOptions())
//       .then((responseJson) => {
//         resolve(responseJson);
//       })
//       .catch((error) => {
//         if (
//           error &&
//           error.hasOwnProperty("response") &&
//           error.response &&
//           error.response.hasOwnProperty("data") &&
//           error.response.data &&
//           error.response.data.hasOwnProperty("error") &&
//           error.response.data.error
//         ) {
//           reject(error?.response?.data?.error?.message);
//         } else {
//           reject(error?.response?.data?.message);
//         }
//       });
//   });
// };

export const ApiDelete = (type, userData) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(BaseURL + type, getHttpOptions())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        if (error.response.status == 401) {
          Auth.Logout();
          window.location.reload();
          // return toast.error("Ihr Konto wurde auf einem anderen Gerät angemeldet" ) +<Redirect  to ="/Login" />
        }
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiDownload = (type, userData) => {
  let method = userData && Object.keys(userData).length > 0 ? "POST" : "GET";
  return new Promise((resolve, reject) => {
    axios({
      url: BaseURL + type,
      method,
      headers: getHttpOptions(),
      data: userData,
    })
      .then((res) => res.blob())
      .then((res) => resolve(res))
      .catch((error) => {
        if (
          error &&
          error.hasOwnProperty("response") &&
          error.response &&
          error.response.hasOwnProperty("data") &&
          error.response.data &&
          error.response.data.hasOwnProperty("error") &&
          error.response.data.error
        ) {
          reject(error?.response?.data?.error?.message);
        } else {
          reject(error?.response?.data?.message);
        }
      });
  });
};

export const ApiGetBuffer = (url) => {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: "GET",
      mode: "no-cors",
    })
      .then((response) => {
        if (response.ok) {
          return response.buffer();
        } else {
          resolve(null);
        }
      })
      .then((buffer) => {
        resolve(buffer);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};

export const getHttpOptions = (options = defaultHeaders) => {
  let headers = {};

  if (options.hasOwnProperty("isAuth") && options.isAuth) {
    if (authUtil.getToken()) {
      headers["Authorization"] = authUtil.getToken();
    } else if (authUtil.getAdminToken()) {
      headers["Authorization"] = authUtil.getAdminToken();
    }
  }

  // if (options.hasOwnProperty("isAuth") && options.isAuth) {
  //   headers["x-auth-token"] = Auth.getToken();
  // }

  if (options.hasOwnProperty("isJsonRequest") && options.isJsonRequest) {
    headers["Content-Type"] = "application/json";
  }

  if (options.hasOwnProperty("AdditionalParams") && options.AdditionalParams) {
    headers = {
      ...headers,
      ...options.AdditionalParams,
    };
  }

  return {
    headers,
  };
};
