import React, { useEffect } from "react";
import ExploreProject from "./exploreProject";
import HeroSection from "./heroSection";
import "./home.scss";
import MarketSection from "./marketSection";
import NewsSection from "./newsSection";
import OurBenefits from "./ourBenefits";

export default function Home() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <>
        <HeroSection />
      </>
      <>
        <ExploreProject />
      </>
      <>
        <MarketSection />
      </>
      <>
        <OurBenefits />
      </>
      <>{/* <NewsSection /> */}</>
    </>
  );
}
