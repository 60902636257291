import React, { useEffect, useState } from "react";
import UploadImage from "../../../assets/images/uploads.jpg";
import Agriculture from "../../../assets/images/agricuttureFarm.jpg";
import SGSImage from "../../../assets/images/sgs.jpg";
import SaImage from "../../../assets/images/sa.jpg";
import IsoImage from "../../../assets/images/iso.jpg";
import "./ourBenefits.scss";
import { ApiGet } from "../../../Helpers/Api/ApiData";
import { NavLink } from "react-router-dom";
export default function OurBenefits() {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [sectionData, setSectiondata] = useState([]);
  const [galleryData, setGalleryData] = useState();

  useEffect(() => {
    GetBenefit();
    GetAllgallery();
  }, []);

  const GetBenefit = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`ourBenefitsSection`)
      .then((res) => {
        console.log("benefits", res?.data?.data?.result);
        if (res?.status === 200) {
          setSectiondata(res?.data?.data?.result);
          setIsLoaderVisible(false);
        }
      })
      .catch((err) => {
        console.log("err", err?.message);
      });
  };

  const GetAllgallery = async () => {
    setIsLoaderVisible(true);
    console.log("mid in GetAllgallery");
    await ApiGet(`gallary/list`)
      .then((res) => {
        console.log("gallery===", res?.data?.data?.result);
        setIsLoaderVisible(false);
        setGalleryData(res?.data?.data?.result);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoaderVisible(false);
      });
    setIsLoaderVisible(false);
  };

  return (
    <>
      <div className="our-benefits-content-banner-alignment">
        <div className="container">
          <div className="grid">
            <div className="grid-items">
              <span>Our Benefits</span>
              <h1>{sectionData?.title}</h1>
              <div className="text-style">
                <p
                  className="animate__animated animate__slideInUp wow"
                  data-wow-offset="150"
                >
                  {
                    <p
                      dangerouslySetInnerHTML={{
                        __html: sectionData?.description,
                      }}
                    />
                  }
                </p>
              </div>
            </div>
            <div className="grid-items">
              <div className="text-style">
                <div className="image-style">
                  <img
                    className="animate__animated animate__fadeInRight wow"
                    data-wow-offset="150"
                    // src={Agriculture}
                    src={`https://api.magnitochemicals.com${sectionData?.image}`}
                    alt="Agriculture"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="home-page-gallery-section">
        <div className="container">
          <div className="page-title">
            <h1>Gallery</h1>
            <div className="divider-title-center">
              <span></span>
            </div>
          </div>
          <div className="three-col-grid">
            {galleryData?.slice(0, 3)?.map((item) => {
              return (
                <>
                  <div className="three-col-grid-items">
                    {/* <img src="https://images.unsplash.com/photo-1541963463532-d68292c34b19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8Ym9va3xlbnwwfHwwfHw%3D&w=1000&q=80" /> */}
                    <img
                      src={`https://api.magnitochemicals.com${item?.image}`}
                    />
                  </div>
                </>
              );
            })}
          </div>
          <div className="fill-button">
            <NavLink to="/gallery">
              <button>View More</button>
            </NavLink>
          </div>
        </div>
      </div>
      {/* <div className="quality-cation-all-content-alignment">
        <div className="container">
          <div className="page-title">
            <span>Quality Policy</span>
            <h1>CERTIFICATION</h1>
          </div>
          <div className="grid">
            <div className="grid-items">
              <div className="card-image">
                <img src={SGSImage} alt="SGSImage" />
              </div>
              <div className="card-details">
                <span>GMP Certificate</span>
              </div>
            </div>
            <div className="grid-items">
              <div className="card-image">
                <img src={SaImage} alt="SaImage" />
              </div>
              <div className="card-details">
                <span>SA 8000:2008</span>
              </div>
            </div>
            <div className="grid-items">
              <div className="card-image">
                <img src={IsoImage} alt="IsoImage" />
              </div>
              <div className="card-details">
                <span>ISO Certificate</span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}
