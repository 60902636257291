import React, { useContext, useEffect, useState } from "react";
import "./footer.scss";
import Logo from "../../assets/logo/logo.png";

import Phone from "../../assets/icons/phone.svg";
import PrivatePolicy from "../../assets/icons/privatePoilcy.svg";
import Email from "../../assets/icons/emailIcon.svg";
import { ApiGet } from "../../Helpers/Api/ApiData";
import { NavLink } from "react-router-dom";
import { DataLoaded } from "../../App";

export default function Footer() {
  const [footerData, setFooterData] = useState([]);

  const { Logo } = useContext(DataLoaded);
  const [hlogo, setHlogo] = Logo;
  console.log("footerData", footerData);

  useEffect(() => {
    GetSection();
  }, []);

  const GetSection = async () => {
    // setIsLoaderVisible(true);
    await ApiGet(`footerSection`)
      .then((res) => {
        console.log("first", res?.data?.data?.aboutUs);
        if (res?.status === 200) {
          setFooterData(res?.data?.data?.aboutUs);
          setHlogo(res?.data?.data?.aboutUs);
          // setIsLoaderVisible(false);
        }
      })
      .catch((err) => {
        console.log("err", err?.response);
      });
  };

  return (
    <>
      <div className="footer-first-section-alignment">
        <div className="container">
          <div className="page-title">
            <h1
              className="animate__animated animate__slideInUp wow"
              data-wow-offset="150"
            >
              PROVIDE YOU THE HIGHEST QUALITY PRODUCTS THAT MEETS YOUR
              EXPECTATION
            </h1>
            <div className="button-center-alignment">
              <div className="fill-button">
                {/* <NavLink to={'/'}>

                <button
                  className="animate__animated animate__slideInUp wow"
                  data-wow-offset="150"
                  >
                  Discover More
                </button>
                  </NavLink> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer>
        <div className="container">
          <div className="footer-grid">
            <div className="footer-grid-items">
              <div className="logo">
                <img
                  src={`https://api.magnitochemicals.com${footerData?.logo}`}
                  alt="Logo"
                />
              </div>
              <div className="footer-company-location">
                <h5>Magnito Chemical Industries LLP</h5>
                <p>
                  Plot No- C-1/129, Nr.JB Chemicals, Ankleshwar, Gujarat 393002
                </p>
              </div>
            </div>
            <div className="footer-grid-items">
              <div className="footer-sub-title">
                <h2>Additional Quick Links</h2>
                <div className="footer-menu-alignment">
                  <div>
                    <a href="/">HOME</a>
                    <a href="/about">About us</a>
                    <a href="/service-page">Services</a>
                    <a href="/gallery">Gallery</a>
                    <a href="/product">Products</a>
                    <a href="/Contact">Contact Us</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-grid-items">
            <h2 className="asas">Contact</h2>
              <div className="footer-contact-section">
                <div className="details-alignment">
                  <div className="img-alignment">
                    <img src={Phone} alt="Phone" />
                    <p>Phone</p>
                  </div>
                  <a
                    className="number-alignment"
                    href={`tel:${footerData?.mobile}`}
                  >
                    +91 {footerData?.mobile}
                  </a>
                </div>
                <div className="details-alignment">
                  <div className="img-alignment">
                    <img src={Email} alt="Email" />
                    <p>Email</p>
                  </div>
                  <a
                    className="number-alignment"
                    href={`mailto:${footerData?.email}?subject = Feedback&body = Message`}
                  >
                    {footerData?.email}
                  </a>
                </div>
                {/* <div className="details-alignment">
                  <div className="img-alignment">
                    <img src={PrivatePolicy} alt="PrivatePolicy" />
                    <p>Privacy</p>
                  </div>

                  <a className="number-alignment">{footerData?.privacy_info}</a>
                </div> */}
              </div>
            </div>
          </div>
          <div className="copyright-content-alignment">
            <div>
              <p>{footerData?.copyright_title}</p>
            </div>
            {/* <div>
              <a>{footerData?.terms_use_title} </a>
              <a>{footerData?.privacy_title}</a>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}
