import React from "react";
import "./heroSection.scss";
import HeroVideo from "../../../assets/images/new-home-screen.png";
import HeroVideoMobile from "../../../assets/images/mobile-hero.png";
import { DataLoaded } from "../../../App";
import { NavLink } from "react-router-dom";
export default function HeroSection() {
  return (
    <>
      <div className="hero-section-alignment">
        <div className="video-relative">
          <img className="mobile-none" src={HeroVideo} alt="HeroVideo" />
          <img
            className="mobile-show"
            src={HeroVideoMobile}
            alt="HeroVideoMobile"
          />
          <div className="video-blur"></div>
          <div className="video-content-alignment">
            <div className="container">
              <div className="hero-text-style">
                <p
                  className="animate__animated animate__slideInUp wow"
                  data-wow-offset="150"
                >
                  THE BEST AGRICULTURE PRODUCTS
                </p>
                <br />
                <h2 className="line-1 anim-typewriter">
                  Welcome To Magnito Chemicals
                </h2>
                <span
                  className="animate__animated animate__slideInUp wow"
                  data-wow-offset="150"
                >
                  {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Error atque commodi voluptates nobis consequuntur sed,
                  corrupti autem unde veniam voluptatem eius. Voluptatibus sunt
                  laudantium dolores distinctio deserunt quos excepturi ipsam. */}
                </span>
                <div className="fill-button">
                  <NavLink to={`/about`}>
                    <button>Learn More</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
