import React, { useEffect, useState } from "react";
import "./contact.scss";
import Logo from "../../assets/logo/logo.png";
import Loader from "../../assets/logo/loader.png";
import "../../components/Loader.css";
import { ApiPost } from "../../Helpers/Api/ApiData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import {
//   withGoogleMap,
//   GoogleMap,
//   withScriptjs,
//   Marker,
//   InfoWindow,
// } from "react-google-maps";
// import { compose, withProps } from "recompose";
import Country from "../../components/Country/Country";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const apiKey =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyB3F4-vg4B4P1Bjzxts8U-XiOK6LGFgqQ8&v=3.exp&libraries=geometry,drawing,places";

export default function Contact() {
  const [inputValue, setInputValue] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showMarker, setShowMarker] = useState(false);
  const [phone, setPhone] = useState("91");
  const regexEmail =
    /^(([^<>()[\],;:\s@]+([^<>()[\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i;

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handle = () => {
    setShowMarker(!showMarker);
  };

  // const MyMapComponent = compose(
  //   withProps({
  //     googleMapURL: apiKey,
  //     loadingElement: <div style={{ height: `100%` }} />,
  //     containerElement: <div style={{ height: `400px` }} />,
  //     mapElement: <div style={{ height: `100%` }} />,
  //   }),
  //   withScriptjs,
  //   withGoogleMap
  // )((props) => (
  //   <GoogleMap
  //     defaultZoom={17}
  //     defaultCenter={{ lat: 21.631597, lng: 73.0182698 }}
  //     // onClick={(e) => handleClickedMap(e)}
  //   >
  //     <Marker position={{ lat: 21.631597, lng: 73.0182698 }} onClick={handle}>
  //       {showMarker && (
  //         <InfoWindow>
  //           <div style={{ color: "black" }}>
  //             <h3 style={{ marginBottom: "5px" }}>
  //               Magnito Chemical Industries LLP
  //             </h3>
  //             <h4>Ankleshwar,</h4>
  //             <h4>Gujarat 393002</h4>
  //           </div>
  //         </InfoWindow>
  //       )}
  //     </Marker>
  //   </GoogleMap>
  // ));

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (name === "first_name" && value.charAt(e.length) == " ") return;
    if (name === "surname" && value.charAt(e.length) == " ") return;
    else {
      setInputValue({ ...inputValue, [name]: value });
      setErrors({ ...errors, [name]: "" });
    }
  };
  console.log("inputvalue", inputValue);

  const Validation = () => {
    let error = {};
    let Formvalid = true;
    if (!inputValue?.first_name) {
      Formvalid = false;
      error["first_name"] = "First name is required*";
    }
    if (!inputValue?.surname) {
      Formvalid = false;
      error["surname"] = "Last name is required*";
    }
    if (!inputValue.email || regexEmail.test(inputValue.email) === false) {
      Formvalid = false;
      error["email"] = "Email is not valid*";
    }
    if (inputValue?.phone?.length < 10) {
      Formvalid = false;
      error["phone"] = "Phone number is not valid*";
    }
    if (!inputValue?.phone || inputValue?.phone < 10) {
      Formvalid = false;
      error["phone"] = "Phone number is required*";
    }
    if (!inputValue?.message) {
      Formvalid = false;
      error["message"] = "Message is required*";
    }
   
    setErrors(error);
    console.log("Formvalid", Formvalid);
    return Formvalid;
  };

  const onSubmit = async () => {
    // let data = {
    //   first_name: inputValue?.first_name,
    //   surname: inputValue?.surname,
    //   email: inputValue?.email,
    //   phone: inputValue?.phone,
    //   message: inputValue?.message,
    // };
    inputValue.phone = phone;
    if (Validation()) {
      setIsLoading(true);
      await ApiPost(`contactUs`, inputValue)
        .then((res) => {
          if (res?.status === 200) {
            console.log("res", res);
            toast.success("Data submitted successfully");
            setInputValue({
              first_name: "",
              surname: "",
              email: "",
              phone: "",
              message: "",
            });
            setPhone("")
            setIsLoading(false);
          }
        })
        .catch((error) => {
          console.log("erroe", error);
          toast.error("Form is not submitted");
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="new-page-banner-service">
        <div className="container">
          <div className="new-box-center-alignment">
            <div className="new-box">
              <h1>Our Contact</h1>
              <p>
                <span>Home</span> - Contact
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-information-content-alignment">
        <div className="container">
          <div className="grid">
            <div className="grid-items">
              <div className="my-12">
                {/* <MyMapComponent key="map" /> */}
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3708.831471643294!2d73.01607041533853!3d21.631489772303436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be023cba43823f7%3A0x943a68a2d861ec6b!2sVijay%20Industries!5e0!3m2!1sen!2sin!4v1665989737088!5m2!1sen!2sin"
                  
                  style={{ border: "0" }}
                  loading="lazy"
                ></iframe>
              </div>
            </div>
            <div className="grid-items">
              <div className="logo-alignment">
                <img src={Logo} alt="Logo" />
              </div>
              <div className="input-grid">
                <div className="input-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      placeholder="Frist name*"
                      name="first_name"
                      value={inputValue?.first_name}
                      onChange={(e) => handleOnChange(e)}
                    />
                    <span className="spanError">{errors["first_name"]}</span>
                  </div>
                </div>
                <div className="input-grid-items">
                  <div className="input">
                    <input
                      type="text"
                      placeholder="Surname*"
                      name="surname"
                      value={inputValue?.surname}
                      onChange={(e) => handleOnChange(e)}
                    />
                    <span className="spanError">{errors["surname"]}</span>
                  </div>
                </div>
                <div className="input-grid-items">
                  <div className="input">
                    <input
                      type="email"
                      placeholder="Email*"
                      name="email"
                      value={inputValue?.email}
                      onChange={(e) => handleOnChange(e)}
                    />
                    <span className="spanError">{errors["email"]}</span>
                  </div>
                </div>
                <div className="input-grid-items">
                  <div className="input contry-code-alignment">
                    {/* <select
                      value={inputValue.country_code && inputValue.country_code}
                      name="country_code"
                      onChange={(e) => handleOnChange(e)}
                    >
                      <option value="">Select</option>
                      {Country &&
                        Country.Country.map((e, key) => {
                          return (
                            <option key={key} value={e.Key}>
                              {e.Idc + " " + "(" + e.Name + ")"}
                            </option>
                          );
                        })}
                    </select> */}
                    <ReactPhoneInput
                      country={"in"}
                      enableSearch={true}
                      value={phone}
                      onChange={(phone) => setPhone(phone)}
                    />
                    {/* <input
                      type="number"
                      placeholder="Phone*"
                      name="phone"
                      value={inputValue?.phone}
                      onChange={(e) =>
                        e.target.value.length <= 10 && handleOnChange(e)
                      }
                    />
                    <span className="spanError">{errors["phone"]}</span> */}
                  </div>
                </div>
              </div>
              <div className="input">
                <textarea
                  placeholder="Message*"
                  name="message"
                  value={inputValue?.message}
                  onChange={(e) => handleOnChange(e)}
                ></textarea>
                <span className="spanError">{errors["message"]}</span>
              </div>
              <div className="fill-button">
                <button
                  onClick={(e) => onSubmit(e)}
                  style={{ display: "flex" }}
                >
                  Submit
                  {isLoading == true && (
                    <div class="lds-roller">
                      <img src={Loader} className="Loader-image" alt="loader" />
                    </div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
