import React, { useEffect, useState } from "react";
import "./ourTeam.scss";
import ProfileImage from "../../../assets/images/profile-image.jpg";
import { ApiGet } from "../../../Helpers/Api/ApiData";
import { NavLink } from "react-router-dom";
export default function OurTeam() {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [sectionData, setSectiondata] = useState([]);

  useEffect(() => {
    GetFounder();
  }, []);

  const GetFounder = async () => {
    setIsLoaderVisible(true);
    await ApiGet(`/founderSection`)
      .then((res) => {
        console.log("founder", res?.data?.data?.result);
        if (res?.status === 200) {
          setSectiondata(res?.data?.data?.result);
          setIsLoaderVisible(false);
        }
      })
      .catch((err) => {
        console.log("err", err?.message);
      });
  };
  return (
    <>
      <div className="our-team-section-alignment">
        <div className="container">
          <div className="left-right-content-alignment">
            <div className="grid">
              <div className="grid-items">
                <div className="profile-image-style">
                  <img
                    className="animate__animated animate__fadeInUp wow"
                    data-wow-offset="150"
                    // src={ProfileImage}
                    src={`https://api.magnitochemicals.com${sectionData?.image}`}
                    alt="ProfileImage"
                  />
                </div>
              </div>
              <div className="grid-items">
                <h4
                  className="text-grid animate__animated animate__fadeInUp wow"
                  data-wow-offset="150"
                >
                  {sectionData?.name}
                </h4>
                <p
                  className="text-grid animate__animated animate__fadeInUp wow"
                  data-wow-offset="150"
                >
                  {sectionData?.description}
                </p>
                <div className="fill-button">
                  <NavLink to="/Contact">
                    <button
                      className="text-grid animate__animated animate__fadeInUp wow"
                      data-wow-offset="550"
                    >
                      Contact Us
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
