import React from "react";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Header from "../components/header/index";
import Footer from "../components/footer/index";
import Home from "./home";
import Contact from "./contact";
import About from "./about";
import Product from "./product";
import Blog from "./blog";
import ProductDetails from "./product/productDetails";
import Gallery from "./gallery";
import MarketSection from "./home/marketSection";
import PageTop from "../components/pageTop";
import Service from "./Services/Service";

export default function Routes() {
  return (
    <>
      <Router>
        <PageTop />
        <Header />
        <Route exact path="/" component={Home} />
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/about" component={About} />
        <Route exact path="/product" component={Product} />
        <Route exact path="/service-page" component={Service} />
        <Route exact path="/blog" component={Blog} />
        <Route exact path="/productDetails/:id" component={ProductDetails} />
        <Route exact path="/gallery" component={Gallery} />
        <Footer />
      </Router>
    </>
  );
}
