const protocol = "https"; //live
const host = "api.magnitochemicals.com"; // for live PRODUCTION
// const host = "api.magnito.rejoicehub.com"; // for live

// const protocol = "http"; //local
// const host = "192.168.29.79:5050"; //for local

const hostUrl = `${protocol}://${host}/api/v1/`;
const hostUrlForSocket = `${protocol}://${host}/`;

export const API = {
  protocol: protocol,
  host: host,
  hostUrl: hostUrl,
  hostUrlForSocket: hostUrlForSocket,
};
